import React from 'react'
import { graphql, Link } from 'gatsby'

import classnames from 'classnames'
import { Container, Row, Col, Button } from 'reactstrap'

import Layout from '../components/layout'

import Seo from '../components/shared/Seo'
import Icons from '../components/shared/Icons'
import Legal from '../components/shared/Legal'
import Slugify from '../components/sluggify'

export default class ProteoLitePage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { input: '' }
  }
  render() {
    const {
      title,
      aboutTitle,
      aboutText,
      aboutImage,
      featuresTitle,
      advantagesTitle,
      advantagesText,
      boxText,
      boxTitle,
      features,
      image,
    } = this.props.data.page
    return (
      <Layout>
        <Seo {...this.props.data.seo} />
        <div className="bg-dark text-white">
          {' '}
          <Container className="py-3 ">
            <Row className="my-4 justify-content-center">
              <Col md="7" className="d-flex flex-column justify-content-end">
                <h2 className="mb-3 text-gold">{title}</h2>
                <Row>
                  <Col md="8" lg="6">
                    {' '}
                    <input
                      type="email"
                      placeholder="Enter Your Email"
                      style={{ height: '60px' }}
                      onChange={e => {
                        this.setState({ input: e.target.value })
                      }}
                      value={this.state.input}
                      className="w-100 mt-md-3 d-flex text-center align-middle mb-4 white-btn-gradient"
                    />
                    <div>
                      <Button
                        style={{ height: '60px', lineHeight: 1 }}
                        size="lg"
                        tag={Link}
                        to="/contact"
                        state={{
                          selected: 'Contact Us',
                          email: this.state.input,
                        }}
                        className="w-100 text-white py-3  text-center   primary-btn-gradient"
                      >
                        Get Started
                      </Button>
                    </div>
                    <Legal dark className="text-center pb-4 pb-md-0" />
                  </Col>
                </Row>
              </Col>

              <Col md={{ size: 5 }}>
                <img
                  src={image.file.url}
                  alt={image.description}
                  title={image.title}
                  className="img-fluid ml-auto mb-3 border-40"
                />
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <Container>
            <Row className="py-5">
              <Col
                md="12"
                className="d-flex  flex-column justify-content-center"
              >
                <h3 className="text-center text-primary mb-4">{aboutTitle}</h3>

                <Row className="align-items-center">
                  <Col md="7">
                    <div
                      className="text-justify"
                      dangerouslySetInnerHTML={{ __html: aboutText.g.html }}
                    />
                  </Col>
                  <Col md="5">
                    <img
                      className="w-100 border-40"
                      src={aboutImage.file.url}
                      alt={aboutImage.description}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="advantages" className="py-5 bg-world">
          <Container>
            <Row>
              <Col md="7">
                <h3 className="text-primary mb-5">{advantagesTitle}</h3>
                <div
                  className="text-white text-justify"
                  dangerouslySetInnerHTML={{ __html: advantagesText.g.html }}
                />
              </Col>
              <Col md="5">
                <div className="bg-dark py-7 px-3">
                  <h4 className="text-center text-gold mb-4">{boxTitle}</h4>
                  <div
                    className="text-white text-center"
                    dangerouslySetInnerHTML={{ __html: boxText.g.html }}
                  />
                  <div className="text-center">
                    {' '}
                    <Button className="px-5 mt-4" color="primary">
                      Request Demo
                    </Button>
                  </div>{' '}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div>
          <h3 className="text-center text-primary py-5">{featuresTitle}</h3>
        </div>
        {features.map((feature, i) => {
          return (
            <div
              className={classnames(' py-5', {
                'bg-world text-white': i % 2 === 1,
              })}
              key={i}
            >
              <Container>
                <Row className="py-3">
                  <Col
                    md={{
                      size: 6,
                      order: feature.flipped ? 1 : 0,

                      offset: feature.flipped ? 1 : 0,
                    }}
                    className="d-flex flex-column justify-content-between"
                  >
                    <h3
                      id={Slugify(feature.title)}
                      className="mb-3 text-gold text-lg-left text-center"
                    >
                      {feature.title}
                    </h3>
                    <div
                      className="text-justify"
                      dangerouslySetInnerHTML={{ __html: feature.text.g.html }}
                    />
                    <span>
                      {' '}
                      <Button
                        size="lg"
                        tag={Link}
                        to="/contact"
                        className="text-white py-2 px-5 mb-3 mb-md-0 text-center primary-btn-gradient"
                      >
                        {' '}
                        Try for free
                      </Button>
                    </span>
                  </Col>

                  <Col
                    md={{
                      size: 5,
                      offset: feature.flipped ? 0 : 1,
                    }}
                    className="d-flex align-items-center"
                  >
                    <div
                      className={classnames('border-40', {
                        'ml-auto': !feature.flipped,
                      })}
                      title={feature.image.title}
                      style={{
                        background: `url(${feature.image.file.url})`,
                        backgroundSize: 'cover',
                        backgroundPosition: '50% 50%',
                        backgroundRepeat: 'no-repeat',
                        paddingBottom: '75%',
                        width: '90%',
                        borderRadius: '21px',
                      }}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
          )
        })}
        <Icons />
      </Layout>
    )
  }
}

export const query = graphql`
  {
    seo: contentfulProteoLitePage {
      title: pageTitle
      description
      keywords
    }
    page: contentfulProteoLitePage {
      id
      title
      image: heroImage {
        title
        description
        file {
          url
        }
      }
      aboutTitle
      aboutText {
        g: childMarkdownRemark {
          html
        }
      }
      aboutImage {
        description
        file {
          url
        }
      }
      featuresTitle
      advantagesTitle
      advantagesText {
        g: childMarkdownRemark {
          html
        }
      }
      boxText {
        g: childMarkdownRemark {
          html
        }
      }
      boxTitle

      features {
        title
        text {
          g: childMarkdownRemark {
            html
          }
        }
        flipped
        image {
          description
          file {
            url
          }
        }
      }
    }
  }
`
